/* eslint-disable no-console */

import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

class Illustration extends Component {
  static propTypes = {
    intl: intlShape.isRequired
  };

  state = {
    TranslatedIllustration: null
  };

  componentDidMount() {
    const {
      intl: { locale }
    } = this.props;
    import(`assets/svgs/tripartism-${locale}.svg`)
      .then(SVG => this.setState({ TranslatedIllustration: SVG.default }))
      .catch(error => console.error(error));
  }

  render() {
    const { TranslatedIllustration } = this.state;
    if (!TranslatedIllustration) {
      return <svg {...this.props} />;
    }
    return <TranslatedIllustration direction="ltr" {...this.props} />;
  }
}

export default injectIntl(Illustration);
