import React from 'react';
import Iceberg from 'modules/Iceberg';

import Layout from 'containers/Layout';
import { Head, Menu } from 'components/index';

function IcebergPage() {
  return (
    <Menu>
      <Head title="equality_heading" description="equality_title" />
      <Layout>
        <Iceberg
          slides={[
            {
              message: 'future_visIceberg1',
              image: 'future/iceberg-technology',
              caption: 'future_quoteKofiCaption'
            },
            {
              message: 'future_visIceberg2',
              image: 'future/iceberg-climate',
              caption: 'future_quoteKofiCaption'
            },
            {
              message: 'future_visIceberg3',
              image: 'future/iceberg-children',
              caption: 'future_quoteKofiCaption'
            }
          ]}
        />
      </Layout>
    </Menu>
  );
}

IcebergPage.propTypes = {};

export default IcebergPage;
