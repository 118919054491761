import styled, { keyframes, css } from 'styled-components';
import { skyblue, white } from 'styles/variables';
import { paragraphMixin, captionMixin } from 'styles/typography';
import { Button } from 'modules/SocialJustice/styled';
import { Dots as SliderDots, Dot as SliderDot } from 'components/Slider/styled';
import matchColor from 'utils/matchColor';
import Image from 'components/Image';
import media from 'styles/media';

export const Wrapper = styled.section`
  height: auto;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(${skyblue} 52%, #0f628b 52% 100%);
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${media.sm`
    justify-content: start;
  `};
`;

export const IcebergOuter = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 0;
`;

export const IcebergInner = styled.div`
  width: 100%;
  height: 200vh;
  position: relative;
  bottom: 0;
  background: linear-gradient(${skyblue} 52%, #0f628b 52% 100%);
  transform: ${props =>
    props.surface ? 'translateY(15%)' : 'translateY(-50%)'};
  transition: transform 1000ms ease;
`;

const bobUpAndDownWide = keyframes`
  from {
    transform: translate(-15%, -5px);
  }
  to {
    transform: translate(-15%, 10px);
  }
`;

const bobUpAndDownMed = keyframes`
  from {
    transform: translate(-22%, -5px);
  }
  to {
    transform: translate(-22%, 10px);
  }
`;

const bobUpAndDownSmall = keyframes`
  from {
    transform: translate(-22%, 20%);
  }
  to {
    transform: translate(-22%, 21%);
  }
`;

export const Iceberg = styled(Image)`
  width: auto;
  height: 100%;
  position: relative;
  top: 11%;
  animation: ${bobUpAndDownWide} 2500ms ease-in-out infinite alternate;

  ${media.md`
    animation: ${bobUpAndDownMed} 2500ms ease-in-out infinite alternate;
  `};

  ${media.sm`
    top: 6%;
    height: 75%;
    animation: ${bobUpAndDownSmall} 2500ms ease-in-out infinite alternate;
  `};

  ${media.xs`
    animation: none;
    transform: translate(-25%, 25%);
  `};
`;

export const InfoBoxWrapper = styled.div`
  z-index: 1;
  position: relative;

  ${media.sm`
    margin: 6rem 0 0;
  `};
`;

export const InfoBoxWindow = styled.div`
  width: 100%;
  height: 350px;
  background-color: ${white};
  display: flex;
  flex-flow: row;
  overflow: hidden;

  ${media.md`
    margin: 2rem 0 0;
  `};

  ${media.xs`
    flex-flow: column;
    height: 525px;
  `};
`;

export const WindowLeft = styled.div`
  flex-basis: 50%;
  overflow: hidden;

  ${media.xs`
    flex: 0 1 55%;
  `};
`;

export const InfoBoxImage = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => `url(${Image.getImageUrlById(props.id)})`};
  background-size: cover;
  transition: opacity 500ms ease;
`;

export const WindowRight = styled.div`
  ${paragraphMixin};
  padding: 1rem;
  flex-basis: 50%;
  margin: 0;

  ${media.xs`
    flex-basis: 1 0 55%;
  `};
`;

export const InfoBoxCaption = styled.div`
  ${captionMixin};
  margin: 0.5rem 0 0;
  width: 50%;
  transition: color 1000ms ease;
  ${props =>
    !props.surface
      ? css`
          color: white;
        `
      : null};

  ${media.xs`
    display: none;
  `};
`;

export const ControllerWrapper = styled.div`
  margin: 10% 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${media.md`
    margin: 6% 0;
  `};
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const Butt = styled(Button)`
  background-color: #202c45;
  border: none;
`;

export const Dots = styled(SliderDots)`
  position: relative;
  margin: 0 0 2rem;

  ${media.xs`
    justify-content: center;
  `};
`;

export const Dot = styled(SliderDot)`
  &:after {
    content: '\\25CF';
    color: ${props => matchColor(props.dotsColor)};
    opacity: ${props => (props.isSelected ? 1 : 0.5)};
  }
`;
