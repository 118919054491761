import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import {
  InfoBoxWrapper,
  InfoBoxWindow,
  WindowLeft,
  InfoBoxImage,
  WindowRight,
  InfoBoxCaption
} from './styled';

function InfoBox({ slide, surface = true }) {
  return (
    <InfoBoxWrapper>
      <InfoBoxWindow>
        <WindowLeft>
          <InfoBoxImage id={slide.image} />
        </WindowLeft>
        <WindowRight>
          <FormattedHTMLMessage id={slide.message} />
        </WindowRight>
      </InfoBoxWindow>
      <InfoBoxCaption surface={surface}>
        <FormattedMessage id={slide.caption} />
      </InfoBoxCaption>
    </InfoBoxWrapper>
  );
}

InfoBox.propTypes = {
  slide: PropTypes.shape({}).isRequired,
  surface: PropTypes.bool.isRequired
};

export default InfoBox;
