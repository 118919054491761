import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ControllerWrapper, Buttons, Butt, Dots, Dot } from './styled';

function Controller({
  intl: { locale },
  nextSlide,
  prevSlide,
  slides,
  currentSlide,
  dotsColor = 'white'
}) {
  const buttonStyle = dir => {
    if (dir === 'prev') {
      return {
        opacity: currentSlide === 0 ? 0.5 : 1
      };
    }
    return {
      opacity: currentSlide === slides.length - 1 ? 0.5 : 1
    };
  };

  return (
    <ControllerWrapper>
      <Dots style={{ direction: locale === 'ar' ? 'rtl' : 'ltr' }}>
        {slides.map((slide, i) => (
          <Dot
            dotsColor={dotsColor}
            key={slides[i].message}
            isSelected={currentSlide === i}
          />
        ))}
      </Dots>
      <Buttons style={{ direction: locale === 'ar' ? 'rtl' : 'ltr' }}>
        <Butt locale={locale} onClick={prevSlide} style={buttonStyle('prev')}>
          <FormattedMessage id="interface_previous" />
        </Butt>
        <Butt locale={locale} onClick={nextSlide} style={buttonStyle('next')}>
          <FormattedMessage id="interface_next" />
        </Butt>
      </Buttons>
    </ControllerWrapper>
  );
}

Controller.propTypes = {
  nextSlide: PropTypes.func.isRequired,
  prevSlide: PropTypes.func.isRequired,
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentSlide: PropTypes.number.isRequired,
  intl: PropTypes.shape({}).isRequired,
  dotsColor: PropTypes.string.isRequired
};

export default injectIntl(Controller);
