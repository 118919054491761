import styled from 'styled-components';
import {
  yellow,
  titleSans,
  sans,
  serif,
  white,
  black,
  animation
} from 'styles/variables';
import { H2, H3, paragraphMixin } from 'styles/typography';
import arrow from 'assets/images/arrow-scroll.svg';
import Tripartism from 'modules/SocialJustice/Illustration';
import media from 'styles/media';

const height = {
  lg: '100vh',
  xs: '50rem'
};

const innerHeight = {
  lg: '65vh',
  md: '68vh',
  xs: '60%'
};

const padding = {
  leftCell: {
    top: {
      lg: '8rem',
      md: '4rem',
      sm: '4rem',
      xs: '1rem'
    }
  }
};

export const Wrapper = styled.section`
  width: 100%;
  height: ${height.lg};
  min-height: 700px;
  background-color: ${yellow};
  display: flex;
  flex-flow: column;
  justify-content: center;
  overflow: hidden;

  ${media.xs`
    min-height: ${height.xs};
    justify-content: flex-start;
    padding: 1rem 0;
  `};

  ${media.portrait`
    min-height: initial;
    height: 60vh;
  `};
`;

export const InnerWrapper = styled.div`
  width: 100%;
  height: 80%;
  max-height: 500px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;

  ${media.sm`
    justify-content: center;
  `};

  ${media.xs`
    height: auto;
    max-height: initial;
  `};
`;

export const LeftCell = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.md`
    padding: ${padding.leftCell.top.md} 0 0;
  `};

  ${media.xs`
    flex-direction: column-reverse;
    padding: ${padding.leftCell.top.xs} 0 0;
    height: 100%;
  `};
`;

export const RightCell = styled.div`
  position: relative;
  height: ${innerHeight.lg};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  ${media.xs`
    height: auto;
    padding: 2rem 0 0;
  `};
`;

export const Title = styled(H2)`
  font-family: ${titleSans};
  font-size: ${props =>
    props.locale === 'ru' || props.locale === 'ar' ? '4.2rem' : '5.5rem'};
  line-height: ${props =>
    props.locale === 'ru' || props.locale === 'ar' ? '5rem' : '6rem'};
  font-weight: bold;
  text-transform: uppercase;
  color: ${white};
  margin: 0;
  letter-spacing: -1px;
  padding: 0 2rem 0 0;
  padding: ${props => (props.locale === 'ar' ? '0' : '0 2rem 0 0')};

  ${media.md`
    font-size: ${props =>
      props.locale === 'ru' || props.locale === 'ar' ? '3.8rem' : '4.5rem'};
    line-height: 4.8rem;
    padding: 0;
  `};

  ${media.sm`
    font-size: ${props =>
      props.locale === 'ru' || props.locale === 'ar' ? '3rem' : '4rem'};
    line-height: ${props =>
      props.locale === 'ru' || props.locale === 'ar' ? '4rem' : '5rem'};
  `};

  ${media.portrait`
    font-size: ${props =>
      props.locale === 'ru' || props.locale === 'ar' ? '2.4rem' : '3.75rem'};
    line-height: ${props =>
      props.locale === 'ru' || props.locale === 'ar' ? '2.8rem' : '4.5rem'};
  `};

  ${media.xs`
    font-size: 3.8rem;
    line-height: 4rem;
    margin: 0;
    padding: 0;
    hyphens: auto;
  `};
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: left;
  height: 100%;

  ${media.md`
    max-height: 28rem;
  `};

  ${media.xs`
    padding: 0 1rem;
    max-height: initial;
  `};
`;

export const Heading = styled(H3)`
  font-family: ${serif};
  color: ${black};
  font-size: 2.6rem;
  text-align: inherit;
  margin: 0 0 2rem;

  ${media.md`
    font-size: 1.875rem;
    line-height: 2.5rem;
  `};

  ${media.sm`
    font-size: 1.5rem;
    line-height: 2rem;
  `};
`;

export const BodyTextContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  -ms-overflow-style: none;

  ${media.md`
    flex: 0 1 100%;
  `};

  ${media.sm`
    flex: 0 1 auto;
  `};

  ${media.portrait`
    flex: 0 1 200px;
  `};

  ${media.xs`
    flex: 1 0 12rem;
  `};

  &:after {
    background-image: linear-gradient(
      rgba(246, 179, 0, 0) 0%,
      rgba(246, 179, 0, 1) 100%
    );
    content: '';
    display: block;
    height: 2.2rem;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    z-index: 3;
  }
`;

export const BodyText = styled.div`
  padding: 0 12px 0 0;
  padding: ${props => (props.locale === 'ar' ? '0 0 0 12px' : '0 12px 0 0')};
  -ms-overflow-style: none;

  p {
    ${paragraphMixin};

    &:last-child {
      margin-bottom: 2rem;
    }
  }
`;

export const Controls = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: left;
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  ${media.sm`
    justify-content: space-between;
  `};

  ${media.xs`
    display: none;
  `};
`;

export const Button = styled.button`
  height: 3.125rem;
  margin: 0 5px 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${black};
  color: ${white};
  border: 1px solid ${black};
  border-radius: 8px;
  outline: none;
  flex: 0 0 170px;
  position: relative;
  font-family: ${sans};
  font-size: 1rem;
  cursor: pointer;
  transition: opacity 250ms ease;

  ${media.md`
    margin: 0 10px 0 0;
    flex: 0 0 150px;
  `};

  ${media.sm`
    flex: 0 0 45%;
  `};

  &:first-child {
    opacity: ${props => (props.currentSlide === 0 ? 0.4 : 1)};
    &:before {
      content: '';
      background: url(${arrow}) center no-repeat;
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      left: ${props => (props.locale === 'ar' ? 'initial' : '1rem')};
      right: ${props => (props.locale === 'ar' ? '1rem' : 'initial')};
      top: 1.1rem;
      transform: ${props =>
        props.locale === 'ar' ? 'rotate(-90deg)' : 'rotate(90deg)'};
    }
  }

  &:last-child {
    opacity: ${props => (props.currentSlide === props.slides - 1 ? 0.4 : 1)};
    &:after {
      content: '';
      background: url(${arrow}) center no-repeat;
      display: inline-block;
      width: 15px;
      height: 15px;
      position: absolute;
      left: ${props => (props.locale === 'ar' ? '1rem' : 'initial')};
      right: ${props => (props.locale === 'ar' ? 'initial' : '1rem')};
      top: 1.1rem;
      transform: ${props =>
        props.locale === 'ar' ? 'rotate(90deg)' : 'rotate(-90deg)'};
    }
  }
`;

export const Dots = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  ${media.xs`
    justify-content: center;
    margin: 1.5rem 0;
  `};

  button {
    display: none;
    ${media.xs`
      display: inline-block;
      position: absolute;
      border: none;
      padding: 0;
      text-decoration: none;
      background: url(${arrow}) center no-repeat;
      color: ${white};
      cursor: pointer;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 30px;
      height: 30px;

      &:first-child {
        transform: rotate(90deg);
        left: 1rem;
      }

      &:last-child {
        transform: rotate(-90deg);
        right: 1rem;
      }
    `};
  }
`;

export const Dot = styled.span`
  margin: 0 5px;
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;

  &:after {
    content: '\\25CF';
    color: ${white};
    opacity: ${props => (props.thisdot === props.currentSlide ? 1 : 0.5)};
  }

  ${media.xs`
    justify-content: flex-start;
  `};
`;

export const IllustrationBox = styled.div`
  width: 100%;
  height: 0;
  padding-top: 80%; /* Aspect ratio */
  position: relative;
  box-sizing: content-box;

  ${media.md`
    padding-top: 75%;
  `};

  ${media.sm`
    padding-top: 83%;
  `};

  ${media.portrait`
    padding-top: 110%;
  `};

  ${media.xs`
    height: 18rem;
    padding: 0;
  `};

  #circle-centre {
    opacity: ${props => (props.currentSlide === 2 ? 1 : 0)};
  }

  #circle-large-workers {
    opacity: ${props => (props.currentSlide === 3 ? 1 : 0)};
  }

  #circle-large-employers {
    opacity: ${props => (props.currentSlide === 3 ? 1 : 0)};
  }

  #circle-small-governments {
    opacity: ${props => (props.currentSlide === 1 ? 1 : 0)};
  }

  #circle-small-workers {
    opacity: ${props => (props.currentSlide === 1 ? 1 : 0)};
  }

  #circle-small-employers {
    opacity: ${props => (props.currentSlide === 1 ? 1 : 0)};
  }
`;

export const Illustration = styled(Tripartism)`
  height: auto;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  ${media.xs`
    height: 100%;
  `};

  circle {
    transition: opacity ${animation.medium * 2}ms ease;
  }
`;
