import React from 'react';
import PropTypes from 'prop-types';
import { IcebergOuter, IcebergInner, Iceberg } from './styled';

function Ice({surface}) {
  return (
    <IcebergOuter>
      <IcebergInner surface={surface}>
        <Iceberg id="future/iceberg-illustration" />
      </IcebergInner>
    </IcebergOuter>
  );
}

Ice.propTypes = {
  surface: PropTypes.bool.isRequired
};

export default Ice;
