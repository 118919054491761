import styled from 'styled-components';
import { white, grey } from 'styles/variables';
import rightArrow from 'assets/images/arrow-right.svg';
import media from 'styles/media';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column-reverse;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const Arrow = styled.button`
  position: absolute;
  top: 25%;
  left: ${props => (props.left ? '-100px' : 'calc(100% + 100px)')};
  background: center no-repeat url(${rightArrow});
  background-color: none;
  border: none;
  padding: 0;
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  width: 25px;
  height: 25px;
  opacity: ${props => (props.disabled ? 0.2 : 1)};
  transform: ${props => (props.left ? 'scaleX(-1)' : 'none')};
  outline: none;

  ${media.xs`
    display: none;
  `};
`;

export const Dots = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: row nowrap;
  justify-content: center;
  position: absolute;
  position: block;
  margin: 0 auto;
  padding: 0;
  bottom: 0;
  list-style: none;

  ${media.xs`
    position: relative;
    margin: 0 0 2rem 0;
    justify-content: flex-start;
  `}
`;

export const Dot = styled.li`
  display: inline-block;
  margin: 0 2.5px;
  padding: 0;
  border: 0;
  font-size: 1rem;
  position: relative;

  &:after {
    cursor: pointer;
    color: ${props => (props.isSelected ? white : grey)};
    content: '${props => (props.isSelected ? '\\25C6' : '\\25C7')}';
  }

  ${media.xs`
    &:after {
      font-size: 2rem;
      margin: 0 20px 0 0;
      content: '\\25C6';
      color: ${white};
      opacity: ${props => (props.isSelected ? 1 : 0.3)};
    }
  `};
`;
