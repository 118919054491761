import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Soundbyte from 'components/Sound/Soundbyte';

import InfoBox from './InfoBox';
import Controller from './Controller';
import Ice from './Ice';

import { Wrapper } from './styled';

const defaultSlide = {
  message: '',
  image: '',
  caption: ''
};

function Iceberg({ slides = [defaultSlide] }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ iceCreak, setIceCreak] = useState(false);

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(prevSlide => {
        if (prevSlide === 0) {
          setIceCreak(true);
        }
        return currentSlide + 1}
      );
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setIceCreak(false);
      setCurrentSlide(currentSlide - 1);
    }
  };

  const isAboveSurface = currentSlide < 1;

  return (
    <Wrapper>
      {iceCreak && <Soundbyte volume={40} id="future/iceberg" playing />}
      <Ice surface={isAboveSurface} />
      <Outer>
        <Inner>
          <Row>
            <Col
              xs={12}
              smOffset={1}
              sm={10}
              mdOffset={4}
              md={8}
              lgOffset={5}
              lg={7}
            >
              <InfoBox slide={slides[currentSlide]} surface={isAboveSurface} />
              <Controller
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                slides={slides}
                currentSlide={currentSlide}
              />
            </Col>
          </Row>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

Iceberg.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Iceberg;
